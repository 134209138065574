import request from '@/utils/request';

// 液位仪异常告警
export function QueryLevelGaugeError(params) {
  return request({
    url: '/ye_meter_error/data',
    method: 'get',
    params
  });
}

export function ExportLevelGaugeError(params) {
  return request({
    url: '/ye_meter_error/export',
    method: 'get',
    responseType: 'blob',
    params
  });
}

// 油罐高温预警
export function QueryTankHotError(params) {
  return request({
    url: '/tank_hot_error/data',
    method: 'get',
    params
  });
}

export function ExportTankHotError(params) {
  return request({
    url: '/tank_hot_error/export',
    method: 'get',
    responseType: 'blob',
    params
  });
}

// 油罐低温报警
export function QueryTankLowError(params) {
  return request({
    url: '/tank_low_error/data',
    method: 'get',
    params
  });
}

export function ExportTankLowError(params) {
  return request({
    url: '/tank_low_error/export',
    method: 'get',
    responseType: 'blob',
    params
  });
}

// 油罐变更报警
export function QueryTankChangeError(params) {
  return request({
    url: '/tank_change_error/data',
    method: 'get',
    params
  });
}

export function ExportTankChangeError(params) {
  return request({
    url: '/tank_change_error/export',
    method: 'get',
    responseType: 'blob',
    params
  });
}

// 出油异常报警
export function QueryOilOutError(params) {
  return request({
    url: '/oil_out_error/data',
    method: 'get',
    params
  });
}

export function ExportOilOutError(params) {
  return request({
    url: '/oil_out_error/export',
    method: 'get',
    responseType: 'blob',
    params
  });
}

// 探针异常报警
export function QueryTankProbeError(params) {
  return request({
    url: '/tank_probe_error/data',
    method: 'get',
    params
  });
}

export function ExportTankProbeError(params) {
  return request({
    url: '/tank_probe_error/export',
    method: 'get',
    responseType: 'blob',
    params
  });
}
// 计量检定异常报警
export function QueryMeasureVerificationError(params) {
  return request({
    url: '/Typein_seal/measur_alarm_data',
    method: 'get',
    params
  });
}
